<template>
  <div class="tmodal">
    <div class="tmodal__header">
      <div class="tmodal__ico" v-if="$slots.ico">
        <slot name="ico"></slot>
      </div>
      <header class="tmodal__head" v-if="$slots.title">
        <slot name="title"></slot>
      </header>
      <header class="tmodal__head tmodal__head--center" v-if="$slots.titleCenter">
        <slot name="titleCenter"></slot>
      </header>
      <div class="tmodal__steps" v-if="$slots.steps">
        <slot name="steps"></slot>
      </div>
      <div class="tmodal__description" v-if="$slots.description">
        <slot name="description"></slot>
      </div>
      <div
        class="tmodal__description tmodal__description--center"
        v-if="$slots.descriptionCenter"
      >
        <slot name="descriptionCenter"></slot>
      </div>
    </div>
    <div class="tmodal__actions" v-if="$slots.actions">
      <slot name="actions"></slot>
    </div>

    <div class="tmodal__disclaimer" v-if="$slots.disclaimer">
      <slot name="disclaimer"></slot>
    </div>

    <!-- <aside class="tmodal__stripe" v-if="showStripe">
      <img src="../../../assets/img/logos/logo-powered-stripe.svg" alt="" />
      <p>
        Tu dinero está seguro. Lo gestionamos usando la tecnología de
        <router-link to="#" class="link link--underline link--bold">Stripe</router-link>,
        la plataforma de gestión de pagos más segura y potente para gestionar tu dinero.
      </p>
    </aside> -->
  </div>
</template>

<script>
export default {
  name: "ModalTemplate",
  data() {
    return {};
  },
  props: {
    showStripe: false,
  },
};
</script>
