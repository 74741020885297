<template>
  <div>
    <!-- Modal de conexión a Stripe -->
    <button class="button button--primary" @click="stripeSuccess = true">
      {{ $t("piggybank.payout.cta") }}
    </button>

    <modal-dialog
      :show="stripeSuccess || showModal"
      @close="close()"
      target-class="modal__card--mini"
    >
      <ModalTemplateVue>
        <template #title> {{ $t("piggybank.verification.successStartVerfication1") }} </template>
        <template #description>
          {{ $t("piggybank.verification.successStartVerfication2") }}
          <strong>{{ $t("piggybank.verification.successStartVerfication3") }}</strong>.
        </template>
        <template #actions>
          <button
            class="button button--primary button--lg button--ico-end button--w350"
            @click="close()"
          >
          {{ $t("piggybank.verification.successStartGotIt") }}
          </button>
        </template>
      </ModalTemplateVue>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog.vue";
import ModalTemplateVue from "./ModalTemplate.vue";

import { VTooltip } from "floating-vue";
import "floating-vue/dist/style.css";

export default {
  name: "HuchaTransferirStripeOk",
  components: {
    "modal-dialog": ModalDialog,
    ModalTemplateVue,
    VTooltip,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stripeSuccess: false,
    };
  },
  methods: {
    show() {
      this.stripeSuccess = true;
    },
    close() {
      this.stripeSuccess = false;
      this.$emit("close");
    },
  },
  directives: {
    tooltip: VTooltip,
  },
};
</script>

<style lang="scss" scoped></style>
