<template>
  <div class="form-filtro" :class="displayInline ? 'form-filtro--inline' : ''">
    <div class="form-filtro__label" v-if="label">{{ label }}</div>
    <input type="date" class="form-filtro__input" :placeholder="placeholder" v-model="selectedDate"/>
  </div>
</template>

<script>
export default {
  name: "FilterDate",
  props: {
    label: String,
    displayInline: Boolean,
    placeholder: String,
  },
  data() {
    return {
      selectedDate: ''
    };
  },
  watch: {
    selectedDate(newVal) {
      this.$emit('dateUpdated', newVal);
    }
  }
};
</script>
