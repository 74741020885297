<template>
  <ModalTemplate>
    <template #ico>
      <img src="../../../assets/img/ico/ico-color-success.svg" alt="" />
    </template>
    <template #titleCenter> {{ $t("piggybank.payout.success") }} </template>
    <template #descriptionCenter>
      {{ $t("piggybank.payout.successTiming") }}
    </template>
    <template #actions>
      <button class="button button--primary button--ico-end button--w350" @click="finish">
        {{ $t("piggybank.payout.successButton") }}
      </button>
    </template>
  </ModalTemplate>
</template>

<script>
import ModalTemplate from "./ModalTemplate.vue";
export default {
  name: "HuchaTransferirCuentaStep3",
  components: {
    ModalTemplate,
  },
  data() {
    return {};
  },
  methods: {
    backStep() {
      this.$emit("next-step", 1); // Volver
    },
    nextStep() {
      this.$emit("next-step", 3); // Siguiente a confirmación
    },
    finish() {
      this.$emit("finish"); // Completats Steps, TODO redirigir a hucha
    },
  },
};
</script>
