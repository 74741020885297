<template>
  <transition name="bbFade">
    <!-- Tenemos tres tipos de color en notificaciones con el prop tipo -->
    <div
      class="bb-notification"
      :class="[
        tipo == 'primary' ? 'bb-notification--primary' : '',
        tipo == 'success' ? 'bb-notification--success' : '',
        tipo == 'error' ? 'bb-notification--error' : '',
        $slots.link ? 'bb-notification--link' : '',
      ]"
    >
      <div
        class="bb-notification__left"
        :class="$slots.link ? 'bb-notification__left--link' : ''"
      >
        <!-- Elegimos el icono con el prop ico, por defecto es uil-info-circle -->
        <!-- Librebria unicons  -->
        <span class="bb-notification__ico">
          <i class="uil" :class="ico"></i>
        </span>

        <!-- Slot de texto para el label de la notificación -->
        <span class="bb-notification__msg">
          <slot></slot>
        </span>
      </div>
      <!-- Si no usamos el slot para usar un link por defecto aparece la X de cerrar  -->
      <aside
        v-if="!simple"
        class="bb-notification__right"
        :class="$slots.link ? 'bb-notification__right--link' : ''"
      >
        <div class="bb-notification__link" v-if="$slots.link">
          <slot name="link"></slot>
        </div>
        <div class="bb-notification__close" @click="close()" v-else>
          <i class="uil uil-multiply"></i>
        </div>
      </aside>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Notification",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    tipo: {
      type: String,
      default: "bb-notification--primary",
    },
    ico: {
      type: String,
      default: "uil-info-circle",
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close-notification')
    },
  },
};
</script>

<style lang="scss" scoped></style>
