<template>
  <div>
    <!-- Modal de conexión a Stripe -->
    <button class="button button--primary" @click="startTransference">
      {{ $t("piggybank.payout.cta") }}
    </button>

    <modal-dialog
      :show="transferirCuenta"
      @close="transferirCuenta = false"
      target-class="modal__card--mini modal__card--nopadding"
    >
      <div class="mch">
        <template v-if="currentStep != 3">
          <div class="mch__title">{{ $t("piggybank.payout.title") }}</div>
          <div class="mch__steps">
            <Steps :steps="steps" :currentStep="currentStep" />
          </div>
        </template>

        <div class="mch__form">
          <component
            :is="currentStepComponent"
            :saldo="saldo"
            :amount="transferAmount"
            :payouts="payouts"
            @next-step="nextStep"
            @finish="finish"
          ></component>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog.vue";
import ModalTemplate from "./ModalTemplate.vue";

// Steps
import Steps from "./Steps.vue";
import HuchaTransferirCuentaStep1 from "./HuchaTransferirCuentaStep1.vue";
import HuchaTransferirCuentaStep2 from "./HuchaTransferirCuentaStep2.vue";
import HuchaTransferirCuentaStep3 from "./HuchaTransferirCuentaStep3.vue";

export default {
  name: "HuchaTransferirCuenta",
  components: {
    "modal-dialog": ModalDialog,
    ModalTemplate,
    HuchaTransferirCuentaStep1,
    Steps
  },
  props: {
    saldo: {
      type: Number,
      default: 0
    },
    payouts: Array,
    hasIBAN: {
      type: Boolean
    }

  },
  data() {
    return {
      transferirCuenta: false,
      currentStep: 1,
      steps: [
        {
          step: 1,
          name: this.$t("generic.configure")
        },
        {
          step: 2,
          name: this.$t("generic.confirm")
        }
      ],
      transferAmount: 0

    };
  },
  computed: {
    currentStepComponent() {
      switch (this.currentStep) {
        case 1:
          return HuchaTransferirCuentaStep1;
        case 2:
          return HuchaTransferirCuentaStep2;
        case 3:
          return HuchaTransferirCuentaStep3;
        default:
          return HuchaTransferirCuentaStep1;
      }
    }
  },
  methods: {
    show() {
      this.transferirCuenta = true;
    },
    nextStep(step, amount) {
      if (step === 2) {
        this.transferAmount = amount;
      }
      this.currentStep = step;
    },
    backStep(backStep) {
      this.currentStep = backStep;
    },
    finish() {
      console.log("Completat , redirigir a mi hucha");
      this.$router.go();
    },
    startTransference() {
      if (!this.hasIBAN) {
        this.$emit("showIban");
      }
      else this.transferirCuenta = true
    }
  },

  directives: {}
};
</script>

<style lang="scss" scoped></style>
