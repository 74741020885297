<template>
  <div>
    <section class="hucha-list">
      <div class="hucha-list__head">{{ $t("piggybank.list.lastMovements") }}</div>

      <div class="hucha-list__items">
        <div
          class="bbsk"
          style="width: 100%; height: 40px; border-radius: 8px; margin-bottom: 1rem"
        ></div>
        <div
          class="bbsk"
          style="width: 100%; height: 40px; border-radius: 8px; margin-bottom: 1rem"
        ></div>
        <div
          class="bbsk"
          style="width: 100%; height: 40px; border-radius: 8px; margin-bottom: 1rem"
        ></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HuchaListadoAportacionesSkeleton",

  props: {
    payouts: null,
    accountOwner: {
      type: String,
      default: "Para ti",
    },
  },
};
</script>

<style lang="scss" scoped></style>
