var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tipo == 'button')?_c('button',{staticClass:"button button--primary",on:{"click":function($event){_vm.conectarStripe = true}}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_c('span',{staticClass:"link link--bold link--underline",on:{"click":function($event){_vm.conectarStripe = true}}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('modal-dialog',{attrs:{"show":_vm.conectarStripe,"target-class":"modal__card--mini"},on:{"close":function($event){_vm.conectarStripe = false}}},[_c('ModalTemplateVue',{attrs:{"showStripe":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t("piggybank.verification.title"))+"\n      ")]},proxy:true},{key:"description",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t("piggybank.verification.description1")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t("piggybank.verification.description2")))])]},proxy:true},{key:"disclaimer",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            html: true,
            placement: 'bottom',
            content:
              _vm.$t('piggybank.verification.disclaimer'),
            handleResize: false,
            triggers: ['hover', 'focus', 'click'],
          }),expression:"{\n            html: true,\n            placement: 'bottom',\n            content:\n              $t('piggybank.verification.disclaimer'),\n            handleResize: false,\n            triggers: ['hover', 'focus', 'click'],\n          }"}],staticClass:"tmodal-disclaimer"},[_c('div',{staticClass:"tmodal-disclaimer__text"},[_vm._v("\n            "+_vm._s(_vm.$t("piggybank.verification.why"))+"\n          ")]),_vm._v(" "),_c('i',{staticClass:"uil uil-question-circle"})])]},proxy:true},{key:"actions",fn:function(){return [_c('button',{staticClass:"button button--primary button--lg button--ico-end button--w350",on:{"click":_vm.redirectToStripe}},[_vm._v("\n          "+_vm._s(_vm.$t("piggybank.verification.button"))+" "),_c('i',{staticClass:"uil uil-external-link-alt"})])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }