<template>
  <div>
    <!-- Modal de conexión a Stripe -->
    <button
      class="button button--primary"
      @click="conectarStripe = true"
      v-if="tipo == 'button'"
    >
      {{ label }}
    </button>
    <span v-else class="link link--bold link--underline" @click="conectarStripe = true">
      {{ label }}
    </span>

    <modal-dialog
      :show="conectarStripe"
      @close="conectarStripe = false"
      target-class="modal__card--mini"
    >
      <ModalTemplateVue :showStripe="true">
        <template #title>
          {{ $t("piggybank.verification.title") }}
        </template>
        <template #description>
          <p>{{ $t("piggybank.verification.description1") }}</p>
          <p>{{ $t("piggybank.verification.description2") }}</p>
        </template>
        <template #disclaimer>
          <div
            class="tmodal-disclaimer"
            v-tooltip="{
              html: true,
              placement: 'bottom',
              content:
                $t('piggybank.verification.disclaimer'),
              handleResize: false,
              triggers: ['hover', 'focus', 'click'],
            }"
          >
            <div class="tmodal-disclaimer__text">
              {{ $t("piggybank.verification.why") }}
            </div>
            <i class="uil uil-question-circle"></i>
          </div>
        </template>
        <template #actions>
          <button
            class="button button--primary button--lg button--ico-end button--w350"
            @click="redirectToStripe"
          >
            {{ $t("piggybank.verification.button") }} <i class="uil uil-external-link-alt"></i>
          </button>
        </template>
      </ModalTemplateVue>
    </modal-dialog>
  </div>
</template>


<script>
import axios from "axios";


import ModalDialog from "../../../components/ui/ModalDialog.vue";
import ModalTemplateVue from "./ModalTemplate.vue";

import { VTooltip } from "floating-vue";
import "floating-vue/dist/style.css";
import { mapGetters } from "vuex";

export default {
  name: "HuchaTransferirStripe",
  components: {
    "modal-dialog": ModalDialog,
    ModalTemplateVue,
    VTooltip
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  props: {
    label: {
      type: String,
      default: "OK"
    },
    tipo: {
      type: String,
      default: "button"
    }
  },
  data() {
    return {
      conectarStripe: false
    };
  },
  methods: {
    show() {
      this.conectarStripe = true;
    },
    async redirectToStripe() {
      try {
        const accountId = this.user.stripeAccountId;
        const apikey = process.env.STRIPE_HBB_API_KEY;
        let options =
        {
          headers: {
            'Content-Type': 'application/json',
            'x-functions-key': apikey
          }
        }
        const response = await axios.post(process.env.STRIPE_HBB_API_URL + `/account/link/${accountId}`, {}, options)

        if (response.data && response.data.url) {
          window.location.href = response.data.url;
        } else {
          console.error("The URL could not be retrieved.");
        }
      } catch (error) {
        console.error("Error fetching Stripe URL:", error);
      }
    }
  },
  directives: {
    tooltip: VTooltip
  }
};
</script>

<style lang="scss" scoped></style>
