import { render, staticRenderFns } from "./HuchaTransferirStripe.vue?vue&type=template&id=167050fc&scoped=true&"
import script from "./HuchaTransferirStripe.vue?vue&type=script&lang=js&"
export * from "./HuchaTransferirStripe.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167050fc",
  null
  
)

export default component.exports