<template>
  <div class="hla">
    <div class="hla__left">
      <div class="hla__name">{{ accountOwner}} {{ $t("piggybank.list.you") }}</div>
      <div class="hla__transferencia">{{ $t("piggybank.list.payout") }}</div>
    </div>
    <div class="hla__right">
      <div class="hla__amount">-{{ $utils.formatMoney(payout.amount) }}</div>
      <time class="hla__date">{{ payout.createdAtString}}</time>
    </div>
  </div>
</template>

<script>
export default {
  name: "HuchaListadoItem",
  props: {
    payout: {
      type: Object,
      default: () => ({}),
    },
    accountOwner: {
      type: String,
      default: "Para ti",
    },
  },
};
</script>

<style lang="scss" scoped></style>
