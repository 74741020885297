<template>
  <section class="mch__disclaimer">
    <div class="mch__disclaimer-text">
      {{ $t("piggybank.createModal.disclaimer1") }}
      <a href="#" class="link link--underline">Stripe</a> {{ $t("piggybank.createModal.disclaimer2") }}
    </div>
    <div class="mch__disclaimer-logo">
      <img src="../../../assets/img/logos/logo-powered-stripe.svg" alt="" />
    </div>
  </section>
</template>

<script>
export default {
  name: "CrearDisclaimerStripe",
};
</script>
