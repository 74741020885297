<template>
  <div class="form-filtro" :class="displayInline ? 'form-filtro--inline' : ''">
    <div class="form-filtro__label" v-if="label">{{ label }}</div>
    <div class="form-filtro__group">
      <input type="text" class="form-filtro__input" :placeholder="placeholder" v-model="searchTerm" />
      <div class="form-filtro__ico" v-if="ico">
        <i class="uil" :class="ico"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterDate",
  props: {
    label: String,
    displayInline: Boolean,
    placeholder: String,
    ico: String,
  },
  data() {
    return {
      searchTerm: ''
    };
  },
  watch: {
    searchTerm(newVal) {
      this.$emit('searchUpdated', newVal);
    }
  },
};
</script>

<style lang="scss" scoped></style>
