<template>
  <div>
    <section class="hucha-list">
      <div class="hucha-list__head">{{ $t("piggybank.list.lastMovements") }}</div>

      <div class="hucha-list__items" v-for="(item, index) in payouts" :key="index">
        <HuchaListadoAportacionesItem  :payout="item" :accountOwner="accountOwner" />
      </div>
    </section>
  </div>
</template>

<script>
import HuchaListadoAportacionesItem from "./HuchaListadoAportacionesItem.vue";

export default {
  name: "HuchaListadoAportaciones",
  components: {
    HuchaListadoAportacionesItem,
  },
  props: {
    payouts: null,
    accountOwner: {
      type: String,
      default: "Para ti",
    },
  },
};
</script>

<style lang="scss" scoped></style>
